import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

import {
    TextXL,
    WhiteSpace
} from '../components';
import Logo from '../components/Logo';

function Loading() {
    return (
        <Container sx={{textAlign: 'center'}}>
            <WhiteSpace />
            <Logo style={{margin: '240px auto 8px', width: 'min-content'}} size={4}/>
            <TextXL>Loading...</TextXL>
            <Stack sx={{ width: '40%', margin: '12px auto' }} spacing={2}>
                <LinearProgress color="inherit" />
            </Stack>
        </Container>
    )
}

export default Loading