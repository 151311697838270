import { Button } from ".";
import {styled} from '@mui/material/styles';

/**
 * 
 * @param {Array} list 
 * @param {string|number} list.id
 * @param {string|number} list.value
 * @param {string|number} list.title
 * @param {boolean} list.disabled
 */
export function ChooseButton({list = [], size, buttonType='c', value, onChange = () => {}, ...other}) {
  // console.log(other)
  return (
    <ChooseButtonBlock {...other}>
      {
        list.map(v => (
          <Button
            key={v.id}
            size={size}
            type={v.disabled ? 'disabled' : v.id === value ? buttonType : 'unActive'}
            onClick={() => {
              onChange(v.id)
            }}
            style={{width: '100%'}}
          >{v.title || v.id}</Button>
        ))
      }
    </ChooseButtonBlock>
  )
}

const ChooseButtonBlock = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #1cac4d;
  width: min-content;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 0.1rem;
`