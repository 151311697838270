import { Button } from '../components';
import { useEthers } from '../ethers';
import {
    SwitchChain
} from 'ethers-easy'

// wallet button
export function WalletButton({ children, ...props }) {
    const {
        // account,
        unlock,
        connect
    } = useEthers();
    return (
        unlock ?
        children :
        <Button
            onClick={connect}
            {...props}
        >
            Connect
        </Button>
    )
}

// add chain button
/**
 * @param {Object} chainInfo
 * @param {string} chainInfo.name
 * @param {string} chainInfo.chainId hex
 * @param {string} chainInfo.rpcUrl
 * @param {string} chainInfo.blockExplorerUrls
 * @param {string} chainInfo.nativeCurrency.name
 * @param {string} chainInfo.nativeCurrency.symbol
 * @param {number} chainInfo.nativeCurrency.decimals
 */
export function AddChainButton({ children, chainInfo={}, ...props }) {
    const addNet = () => {
        SwitchChain(chainInfo)
    }
    return (
        <Button
            {...props}
            onClick={addNet}
        >
            {children}
        </Button>
    )
}

