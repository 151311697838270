import {
    Erc20,
    MultiCall as MCalls,
    GetGlobalSigner,
    CreateContract,
} from 'ethers-easy'

import IAICoin from './ABI/IAICoin.json'

import {
    Address,
    SatAddress
} from './address'

// 拆开方便多链操作
// function CreateContractInWallet(CreateFun) {
//     return (...arg) => CreateFun(...arg, GetGlobalSigner())
// }

// const Erc20Wallet = CreateContractInWallet(Erc20)
// const MCallsWallet = CreateContractInWallet(MCalls)
// const ContractWallet = CreateContractInWallet(CreateContract)


export async function SetChain(chain) {
    try {
        await SatAddress(chain)
        return [Address(), null]
    } catch (error) {
        console.log(error.message)
        return [null, error.message]
    }
}

let _multi;
export function MultiCall() {
    if (!_multi) SetMultiCall()
    return _multi
}

export function SetMultiCall() {
    _multi = MCalls(Address().MUILT)
}

export function AIAI() {
    return Erc20(Address().AIAI)
}

export function AIPOS() {
    return Erc20(Address().AI_POS)
}

export function AIETH() {
    return Erc20(Address().AI_ETH)
}

export function AIStake() {
    return CreateContract(Address().AI_STAKE, IAICoin)
}

// MultiCall,
// AIAI,
// AIPOS,
// AIETH,
// AIStake