
import Logo from '../../components/Logo'
import { 
    Button,
    TextL,
    // TextS,
    // TextM,
    // TextLinear,
    // WhiteSpace,
    // Card,
    // Input,
    // Tips
} from '../../components'

import Icon, {
    IconImg
} from '../../components/Icon'

import {
    WalletButton
} from '../../ethComponents'

import { useEthers } from '../../ethers'


import {
    ShortAddress,
    // EtherToInt
} from 'ethers-easy'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
// import Skeleton from '@mui/material/Skeleton';
// import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';

// import Dialog from '@mui/material/Dialog';

import NetError from '../NetError'
import Loading from '../Loading'

import {useSetChain} from '../../hook/useSetChain'
import useCopy from '../../hook/useCopy'

import {
    // RouterDrawerProvider,
    useRouterDrawer
} from '../router'


function Address() {
    const { account } = useEthers()
    const buttonAttar = {
        size: 'small',
        type: 'c'
    }
    const copy = useCopy()
    const invitLink = () => {
        copy(window.location.href+'?r=' + account)
    }
    return (
        <WalletButton {...buttonAttar}>
            <Button onClick={invitLink} {...buttonAttar}>{ShortAddress(account)}</Button>
        </WalletButton>
    )
}

function OpenRouter() {
    const {open} = useRouterDrawer()
    return (
        <Icon
            c='icon-icon'
            size='28'
            style={{
                marginLeft: '8px',
                padding: '4px',
                background: '#fff8e5',
                borderRadius: '6px',
                border: '1px solid #3b0901',
                color: '#3b0901'
            }}
            onClick={open}
        />
    )
}

function AppBar() {
    return (
        <>
            <MuiAppBar >
                <Container sx={{marginTop:'10px'}}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Logo size='1.8'> <TextL style={{marginLeft: '6px'}}> {process.env.TITLE} </TextL></Logo>
                        <div>
                            <Address />
                            <OpenRouter />
                        </div>
                    </Stack>
                </Container>
            </MuiAppBar>
            <Toolbar />
        </>
    );
}

function Page({bar,children}) {
    return (
        <>
            {bar || <AppBar />}
            {children}
        </>
    )
}


function Index(props) {
    const chain = useSetChain()
    // console.log(chain)
    const isChainError = !!chain.reData[1]
    return (
        chain.loading ?
        <Loading /> :
        !props.noCheckNet && isChainError?
        <NetError/>:
        <Page {...props} />
    )
}

export default Index