import muiltChain from './muiltChain.json'

let _address;
export async function SatAddress(chain) {
    _address = await import(`./${chain}.js`);
}

export function Address() {
    return _address
}

export function GetAsyncAddress(chain) {
    return muiltChain[chain]
}