
import { useState } from 'react';

function useDialog() {
    const [open, setOpen] = useState(false);
    const [children, setChildren] = useState(null);

    const openDialog = () => {
        setOpen(true);
    }

    const openDialogWithChildren = (children) => {
        setChildren(children);
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const dialog = {
        open,
        onClose: closeDialog,
        children // 会被覆盖
        // ariaLabelLedby: "alert-dialog-title",
        // ariaDescriBedby: "alert-dialog-description"
    }
    return {
        dialog,
        openDialog,
        openDialogWithChildren,
        closeDialog
    }
}

export default useDialog


export function useDrawer(anchor) {
    const [open, setOpen] = useState(false);

    const openDrawer = () => {
        setOpen(true);
    }

    const closeDrawer = () => {
        setOpen(false);
    }

    const drawer = {
        open,
        onClose: closeDrawer,
        anchor
        // ariaLabelLedby: "alert-dialog-title",
        // ariaDescriBedby: "alert-dialog-description"
    }
    return {
        drawer,
        openDrawer,
        closeDrawer
    }
}