
import {
    ShortAddress,
    EtherToInt
} from 'ethers-easy'

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import {styled} from '@mui/material/styles';

import { 
    Button,
    TextL,
    TextXL,
    TextMax,
    TextS,
    TextM,
    TextLinear,
    WhiteSpace,
    Card,
    Input,
    Tips,
    Hr
} from '../../components'

import {
    // IconImg,
    NoData
} from '../../components/Icon'

import {
    ChooseButton
} from '../../components/Button'

import {
    useAIAI,
    useStake,
    useClaim
} from '../../hook/aiCoin/useAI'


const CardTitle = styled('div')`
    display: flex;
    background: #2b2b33;
    width: min-content;
    padding: 8px 26px 0px 8px;
    border-radius: 30px 30px 0 0;
    margin-bottom: -14px;
    align-items: center;
`

const CHOOSE = [
    {id: 'GAGAPOS'},
    {id: 'GAGAW'},
]
function MintPos() {
    const ai = useAIAI()
    const claimButton = useClaim()
    const {
        button,
        stakeType,
        inputAmount
    } = useStake()
    const isPOS = stakeType.value === 'GAGAPOS'
    const tokenBalance = isPOS ? ai.aiposBalance : ai.aiethBalance
    const setMax = () => inputAmount.onChange(tokenBalance)

    const aiDisabled = isPOS && ai.aiaiBalance * 1 < inputAmount.value * 1
    return (
        <Container>
            {/* <CardTitle
                style={{
                    display: 'flex'
                }}
            >
                <IconImg 
                    src='/icon/change.png'
                    size='24'
                    style={{
                        marginRight: '8px',
                        background: '#4e5d21',
                        padding: '6px',
                        borderRadius: '32px'
                    }}
                />
                <TextXL style={{whiteSpace: 'nowrap'}}>GAGA</TextXL>
            </CardTitle> */}
            <IconLogo src='./logo512.png'/>
            <Card spacing={.8} >
                <div style={{height: '14px', width:'100%'}}/>
                <TextM
                   
                    style={{
                        marginBottom: '6px',
                        display: 'block'
                    }}
                >Stake Token</TextM>
                <ChooseButton
                    style={{width: '100%',marginBottom: '18px'}}
                    list={CHOOSE}
                    {...stakeType}
                />
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        padding:'0xp 6px',
                        margin:'10px 0px 6px 0px'
                    }}
                >
                    <TextM >{stakeType.value} Amount</TextM>
                    <TextM>
                        Balance: {EtherToInt(tokenBalance)}
                    </TextM>
                </Stack>
                <Input
                    after={<Button size='small' onClick={setMax} style={{marginLeft: '4px', padding:'2px', minWidth: '41px'}}>Max</Button>}
                    input={inputAmount}
                />
                {
                    isPOS &&
                    <>
                        <WhiteSpace />
                        <DivFlex>
                            <TextM>GAGA Balance</TextM>
                            <TextM>{EtherToInt(ai.aiaiBalance)}</TextM>
                        </DivFlex>
                        <DivFlex>
                            <TextM >Need GAGA</TextM>
                            <TextM>{EtherToInt(inputAmount.value)}</TextM>
                        </DivFlex>
                        <WhiteSpace size='0.2' /> 
                    </>
                }

                <WhiteSpace />
                {
                    aiDisabled && <TextM style={{color: '#c8550e', marginBottom: '4px'}}>GAGA Insufficient balance</TextM>
                }
                {
                    ai.openStake ? 
                    <Button size='large' style={{width:'100%'}} disabled={aiDisabled} {...button} /> :
                    <Button size='large' style={{width:'100%'}} disabled>Coming Soon</Button>
                }
                
                <WhiteSpace size='1.6'/>
                {/* <Hr />
                <WhiteSpace /> */}
                <DivFlex>
                    <div>
                        <TextM color='1' style={{display:'block'}}>Withdrawn</TextM>
                        {/* <WhiteSpace size='.1'/> */}
                        <TextS color='1'>
                            <TextS style={{fontSize: '28px'}}>{EtherToInt(ai.claim)} </TextS> / GAGA
                        </TextS>
                    </div>
                    <Button type='b' size='small' {...claimButton} disabled />
                </DivFlex>
                
            </Card>
            <WhiteSpace />
            <Card spacing={.8}>
                <TextL>Order List</TextL>
                {/* <WhiteSpace /> */}
                {/* totalAIAI: WeiToUnits(v.totalReleaseAIAI).toString(),
                startTime,
                startDate: new Date(startTime).Format('yy.MM.dd hh:mm'),
                token: v.stakeAIToken === aipos.address ? 'AIPOS' : 'AIETH',
                withdrawen */}
                {
                    ai.orders.length === 0 ? 
                    <NoData style={{margin: '20px auto', display:'block', opacity: 0}}/> :
                    ai.orders.map(v => (
                        <Card
                            key={v.startTime}
                            type='b'
                            style={{
                                marginTop: '8px'
                            }}
                            spacing={[.4, .8]}
                        >
                            <DivFlex>
                                <TextS>Total</TextS>
                                <TextS>{EtherToInt(v.totalAIAI)} GAGA</TextS>
                            </DivFlex>
                            <DivFlex>
                                <TextS>Withdrawen</TextS>
                                <TextS>{EtherToInt(v.withdrawen)} GAGA</TextS>
                            </DivFlex>
                            <DivFlex>
                                <TextS>Stake</TextS>
                                <TextS>{EtherToInt(v.stakeAmount)} {v.token}</TextS>
                            </DivFlex>
                            <DivFlex>
                                <TextS>Start Time</TextS>
                                <TextS>{v.startDate}</TextS>
                            </DivFlex>
                        </Card>
                    ))
                }
            </Card>
        </Container>
    )
}

export default MintPos

// const TextCoin = styled(TextM)`
//     padding: 2px 4px;
//     background: #2b2b33;
//     border-radius: 6px;
// `

const DivFlex = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const IconLogo = styled('img')`
    width: 120px;
    display: block;
    margin: auto;
    margin-bottom: -60px;
    max-width: max-content;
    position: relative;
    z-index: -1;
`