import { useSnackbar } from 'notistack'; 

// variant : 'default' | 'error' | 'success' | 'warning' | 'info';
function useToast( ) {
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (content) => {
    enqueueSnackbar(content);
  };

  const handleClickVariant = (content, variant='success') => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      content,
      // { variant: 'default', autoHideDuration: 1000 }
      { variant, autoHideDuration: 1000 }
    );
  };
  return {
    open: handleClickVariant,
    info: handleClick
  }
}

export default useToast

{/* <FlexBlock flex>
        <Icon type={variant==='success'?'icon-chenggong':'icon-cuowu1'} size='16' style={{marginRight:'6px'}}/>
        <TextM color='0'>{content}</TextM>
      </FlexBlock>
      , { variant: 'default', autoHideDuration: 1000 }); */}