// import logo from './logo.svg';
// import './App.css';
import './init.js';

import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import EthersProvider from './ethers';

import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Route,
  // Link,
  // useHistory
} from "react-router-dom";
import {
  RouterDrawerProvider,
  // useRouterDrawer
} from '../src/pages/router'
// import Index from './pages';
// import Theme from './pages/Theme';
// import NetError from './pages/NetError';
// import Loading from './pages/Loading';
// import Bridge from './pages/Bridge';

import {
  // RouterFlat,
  RouterView
} from './pages/router';


const BACKGROUNDCOLOR = '#f4c31e'
const TEXT_DEFULT_STYLE = {
  color: '#fff',
  fontWeight: 800
}
const theme = createTheme({
  palette: {
    // text: {
    //   primary: TEXT_DEFULT_STYLE.color,
    // },
    background: {
      default: BACKGROUNDCOLOR
    }
  },
  typography: {
    // 中文字符和日文字符通常比较大，
    // 所以选用一个略小的 fontsize 会比较合适。
    fontSize: 12,
    subtitle1: TEXT_DEFULT_STYLE,
    body1: TEXT_DEFULT_STYLE,
    button: {
      ...TEXT_DEFULT_STYLE,
      textTransform: 'none'
    }
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The default props to change
        // disableRipple: true, // No more ripple, on the whole application 💣!
        sx: {
          borderRadius: 4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: 'transparent',
          boxShadow: 'none',
          minWidth: '90%'
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0px 8px',
          // margin: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: BACKGROUNDCOLOR,
          boxShadow: 'none',
          transition: 'all .3s',
          borderBottom: '0px solid #f2f2f2',
        },
      },
    },
  },
});

function App() {
  return (
    <Router>
      <EthersProvider
        rpc={process.env.RPC}
        chainId={process.env.CHAINID}
      >
        <SnackbarProvider maxSnack={3}>
        {/* <StyledEngineProvider> */}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* <Index /> */}
            {/* <Theme /> */}
            {/* <NetError /> */}
            {/* <Loading /> */}
            {/* <Bridge /> */}
            {/* <Router>
              {RouterFlat.map(v => <Route key={v.title} {...v}/>)}
            </Router> */}
            <RouterDrawerProvider>
              <RouterView />
            </RouterDrawerProvider>
            {/* {RouterFlat.map(v => <Route key={v.title} {...v}/>)} */}
          </ThemeProvider>
        {/* </StyledEngineProvider> */}
        </SnackbarProvider>
      </EthersProvider>
    </Router>
  );
}

export default App;
