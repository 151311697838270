import {styled} from '@mui/material/styles';
import MButton from '@mui/material/Button';
import MCard from '@mui/material/Card';
import MInput from '@mui/material/Input';

import CircularProgress from '@mui/material/CircularProgress';

export const defaultTheme = {
    hrBackground: '#111305',
    buttonRadius: 0,
    testLinear: [
        `background: linear-gradient(45deg, rgb(130 181 251), rgb(80 77 203))`,
        'background: linear-gradient(120deg,#ff8a38,#ff5400,#ff8a38)'
    ],
    card: {
        shape: {
            borderRadius: '.6rem',
            boxShadow: 'none',
        },
        custom: {
            a: {
                background: '#fff7e3',
                color: '#3b0901',
                border: '1px solid #3b0901'
            },
            b: {
                background: '#ffd873',
                color: '#3b0901',
                border: '1px solid #3b0901'
            },
            c: {
                background: '#39384a',
                color: '#f4f5f8',
            },
            d: {
                background: '#585a69',
                color: '#f1f2fa',
            },
            e: {
                background: '#202026',
                // boxShadow: '0 0 10px #ccf55b',
                color: '#e3dfdf',
            },
            f: {
                background: 'transparent',
                border: '1px dashed #ccf55b',
                // boxShadow: '0 0 10px #ccf55b',
                color: '#1c1d20',
            }
        }
        
    },
    fontSize: {
        small: 12,
        medium: 14,
        large: 20,
        larger: 24,
        max: 30,
    },
    fontColor: ['#3b0901', '#52190a', '#ccf55b', '#caccce'],
    button: {
        type: {
            a: {
                background: '#fec737',
                color: '#3b0901',
                border: '1px solid #3b0901',
                boxShadow: 'inset 0px 0px 2px rgb(59 9 1 / 50%)'
            },
            b: {
                background: '#1cac4d',
                color: '#3b0901',
                border: '1px solid #3b0901',
            },
            c: {
                background: '#fff8e5',
                color: '#3b0901',
                border: '1px solid #3b0901',
            },
            d: {
                background: '#8287be',
                color: '#f1f2fa',
            },
            unActive: {
                background: '#1cac4d',
                color: '#fff',
            },
            disabled: {
                background: '#88899b',
                color: '#c0c0c0',
            },
        }
    },
    input: {
        block: {
            background: '#fce8b7',
            color: '#f1f2fa',
            padding: '0.2rem 0.4rem',
            borderRadius: '10rem',
            border: '1px solid #3b0901',
            boxShadow: 'inset 0px 0px 2px rgb(59 9 1 / 50%)'
        },
        input: {
            background: 'transparent',
            color: '#3b0901',
            fontSize: '1.2rem',
            fontWeight: 500,
            padding: '0 .2rem',
            width: '100%',
        },
        background: '#1d1e23',
        color: '#f1f2fa',
    },
    dialog: {
        background: 'transparent',
    }
}
// button
export function Button({children, size="medium", loading = false, disabled = false, type='a', ...props}) {
    const buttonTheme = defaultTheme.button.type[type]
    console.log(buttonTheme, type)
    return (
        <MButton
            sx={{
                ...buttonTheme,
                borderRadius: '1.2rem',
                pointerEvents: loading ? 'none' : 'auto',
                '&:hover': {
                    background: buttonTheme.background
                },
                "&.Mui-disabled": {
                    ...buttonTheme,
                    opacity: 0.5,
                }
            }}
            size={size}
            disabled={disabled}
            {...props}
            startIcon={
                loading && <CircularProgress
                    size={defaultTheme.fontSize[size]}
                    sx={{
                        color: buttonTheme.color
                    }}
                    thickness={5}
                />
            }
        >
            {children}
        </MButton>
    )
}

// text
const Test = styled('span')`
    color: ${p => defaultTheme.fontColor[p.color || 0]};
    font-weight: 500;
`
export const TextS = styled(Test)`
    font-size: ${defaultTheme.fontSize.small}px;
`
export const TextM = styled(Test)`
    font-size: ${defaultTheme.fontSize.medium}px;
`
export const TextL = styled(Test)`
    font-size: ${defaultTheme.fontSize.large}px;
`
export const TextXL = styled(Test)`
    font-size: ${defaultTheme.fontSize.larger}px;
`
export const TextMax = styled(Test)`
    font-size: ${defaultTheme.fontSize.max}px;
`

export const TextLinear = styled('span')`
    ${props => defaultTheme.testLinear[props.linear || 0]};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`

// card
export const Card = styled(MCard)`
    ${p => {
        const padding = !p.spacing ?
            0 :
            typeof p.spacing === 'number' ?
            p.spacing + 'rem' :
            p.spacing instanceof Array ?
                p.spacing.map(v => v + 'rem').join(' ') :
                0
        return {
            ...defaultTheme.card.shape,
            ...defaultTheme.card.custom[p.type || 'a'],
            padding
        }
    }};
`

// input
export function Input({before, after, input = {}, inputStyle={}, fullWidth=true, ...props}) {
    return (
        <InputBlock fullWidth={fullWidth} {...props}>
            {before}
            <MInput
                disableUnderline
                sx={defaultTheme.input.input}
                inputProps={{
                    style: {
                      padding: 0,
                      ...inputStyle
                    }
                }}
                {...input}
            />
            {after}
        </InputBlock>
    )
}
const InputBlock = styled('div')`
    ${p => {
        return {
            ...defaultTheme.input.block,
            width: p.fullWidth ? '100%' : 'max-content'
        }
    }};
    display: flex;
    align-items: center;
`

export const WhiteSpace = styled('div')`
    width: 100%;
    text-align: center;
    height: ${p => p.size || .8}rem;
`

// hr
export const Hr = styled('div')`
    width: ${props => props.w || 100}%;
    height: 1px;
    transform: scaleY(50%);
    background: ${ p => p.color || defaultTheme.hrBackground };
    ${p => p.padding ? `padding: ${p.padding[0] || 0}rem ${p.padding[1] || 0}rem`:''};
`
export function Fragment({children}) {
    return <>{children}</>
}

export const Tips = styled('span')`
    font-size: 12px;
    /* margin-top: 0.2rem; */
    ${p => defaultTheme.card.custom[p.type || 'a']}
    padding: 0.05rem .2rem;
    border-radius: .2rem;
`
// countdown
// export function Countdown({time, ...props}) {
//     const [count, setCount] = useState(time)
//     useEffect(() => {
//         if (count > 0) {
//             setTimeout(() => {
//                 setCount(count - 1)
//             }, 1000);
//         }
//     }, [count])
//     return (
//         <TextM {...props}>{count}</TextM>
//     )
// }