import { useState, useEffect } from 'react'

import {
    useEthers
} from '../ethers'

import {SetChain, SetMultiCall} from '../chain'

const INIT = {
    loading: true,
    reData: [null, null]
}

export function useSetChain() {
    // account,
    // chainId,
    const {chainId, account} = useEthers()
    const [chain, setChain] = useState(INIT)
    const loading = () => setChain(v => {
        return {
            ...v,
            loading: true
        }
    })
    const loaded = (data) => {
        setChain(v => {
            return {
                reData: data || v.reData, 
                loading: false
            }
        })
    }
    
    // 重新设置 account 的时候，provide 在 useEthers 中被重置，需要重置 contract
    useEffect(() => {
        async function ReSet() {
            loading()
            const chainData = await SetChain(chainId)
            SetMultiCall()
            loaded(
                chainData
            )
            
        }
        ReSet()
    }, [chainId, account])
    return chain
}