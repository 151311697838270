import Container from '@mui/material/Container';
import {
    Button,
    // TextS,
    // TextM,
    // TextL,
    TextXL,
    // TextMax,
    Card,
    // Input,
    WhiteSpace
} from '../components';
import Logo from '../components/Logo';

import {
    SwitchChain
} from 'ethers-easy'

function NetError() {

    const addNet = () => {
        SwitchChain({
            chainId: '0x'+(process.env.CHAINID*1).toString(16),
            chainName: process.env.CHAINNAME,
            nativeCurrency: {
                name: process.env.CHAINNAME,
                symbol: process.env.CHAINCOIN,
                decimals: 18,
            },
            rpcUrls: process.env.RPC,
            blockExplorerUrls: process.env.CHAINSCAN,
        })
    }
    return (
        <Container
            sx={{
                textAlign: 'center',
                marginTop: '2rem',
            }}
        >
            
            <Logo style={{margin: '8px auto', width: 'min-content'}}/>
            <TextXL>Net Error</TextXL>
            <WhiteSpace />
            <Card spacing={1}>
                The current chain ID is not supported. 
                <br />
                Would you like to add the network?
                <WhiteSpace />
                <Button type='c' fullWidth onClick={addNet}>Add {process.env.CHAINNAME}</Button>
            </Card>
        </Container>
    )
}

export default NetError