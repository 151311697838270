
import {styled} from '@mui/material/styles';

import { 
    Button,
    TextL,
    TextXL,
    TextMax,
    TextS,
    TextM,
    TextLinear,
    WhiteSpace,
    Card,
    Input,
    Tips,
    Hr
} from '../../components'

function GACard({children}) {
    return (
        <ContextCard type='b'>
            {children}
            <LogoImgBlock>
                <LogoImg src='./logo.png'/>
                <LogoImg src='./logo.png'/>
                <LogoImg src='./logo.png'/>
            </LogoImgBlock>
            <LeafImg src='./icon/gagaleaf.png'/>
            <LeafImgRight src='./icon/gagaleaf.png'/>
        </ContextCard>
    )
}


export default GACard

const ContextCard = styled(Card)`
    position: relative;
    text-align: center;
    padding: 1.2rem 0 1.6rem;
`

const LogoImg = styled('img')`
    width: 20px;
    display: block;
`

const LogoImgBlock = styled('div')`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: -10px;
`

const LeafImg = styled('img')`
    position: absolute;
    width: 75px;
    bottom: -50px;
    right: -3px;
    transform: rotate(40deg);
`

const LeafImgRight = styled('img')`
    position: absolute;
    width: 75px;
    bottom: -50px;
    left: -3px;
    transform: rotate(-40deg) rotateY(-180deg);
`