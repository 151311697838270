import { useState, useEffect } from 'react'

import {
    CreatNumbrAddress,
    WeiToUnits,
    EtherToWei,
    Approves
} from 'ethers-easy'

import {
    MultiCall,
    AIAI,
    AIPOS,
    AIETH,
    AIStake
} from '../../chain'

import {useEthers} from '../../ethers'

import useButtonText from '../useButtonText'
import useInput from '../useInput';
import useToast from '../useToast'

function WalletError(error) {
    if (!error.data) return error.message
    return error.data.message
}

const ZREO_ADDRESS = CreatNumbrAddress(0)
const INIT = {
    eth: 0,
    aiai: 0,
    aipos: 0,
    aieth: 0,
    aiposSupply: 0,
    aiethSupply: 0,
    isIntvited: false,
    intvite: null,
    openStake: false,
    orders: [],
    invites: []
}

async function main(account) {
    const multi = MultiCall()
    const aiai = AIAI()
    const aipos = AIPOS()
    const aieth = AIETH()
    const aiStake = AIStake()
    const data = await multi.all({
        eth: multi.$.calls.getEthBalance(account),
        aiai: aiai.calls.balanceOf(account),
        aipos: aipos.calls.balanceOf(account),
        aieth: aieth.calls.balanceOf(account),
        aiStakeInvite: aiStake.calls.invite(account),
        claim: aiStake.calls.claimFor(account),
        order: aiStake.calls.ordersOf(account),
        invites: aiStake.calls.inviteUsersOf(account),
        openStake: aiStake.calls.openStake(),
        // aiposSupply: aipos.calls.totalSupply(),
        // aiethSupply: aieth.calls.totalSupply(),
        aiposSupply: aipos.calls.balanceOf(aiStake.address),
        aiethSupply: aieth.calls.balanceOf(aiStake.address),
    })
    
    const isIntvited = ZREO_ADDRESS !== data.aiStakeInvite
    const eth = WeiToUnits(data.eth).toString()
    const aiaiBalance = WeiToUnits(data.aiai).toString()
    const aiposBalance = WeiToUnits(data.aipos).toString()
    const aiethBalance = WeiToUnits(data.aieth).toString()
    const claim = WeiToUnits(data.claim).toString()
    const openStake = data.openStake

    // console.log()
    const aiposSupply = WeiToUnits(data.aiposSupply)
    const aiethSupply = WeiToUnits(data.aiethSupply)
    return {
        aiposSupply,
        aiethSupply,
        isIntvited,
        intvite: data.aiStakeInvite,
        eth,
        aiaiBalance,
        aiposBalance,
        aiethBalance,
        claim,
        openStake,
        orders: data.order.map(v => {
            const startTime = v.startTime.toNumber()
            return {
                totalAIAI: WeiToUnits(v.totalReleaseAIAI).toString(),
                startTime,
                startDate: new Date(startTime).Format('yy.MM.dd hh:mm'),
                token: v.stakeAIToken === aipos.address ? 'GAGAPOS' : 'GAGAW',
                withdrawen: WeiToUnits(v.releasedAIAI).toString(),
                stakeAmount: WeiToUnits(v.stakeTokenAmount).toString()
            }
        }).sort((a,b) => b.startTime - a.startTime),
        invites: data.invites.map(v => {
            return {
                address: v.user,
                totalAIAI: WeiToUnits(v.stakeAmount).toString()
            }
        })
    }

}

export function useAIAI() {
    const {account, getBlockNumber} = useEthers()
    const [data, setState] = useState(INIT)
    useEffect(() => {
        const init = async () => {
            setState(
                await main(account)
            )
        }
        init()
    }, [account, getBlockNumber()])
    return data
}


export function useMint() {
    // const {account, chainId} = useEthers()
    const inputAmount = useInput("", {type: "number", placeholder: "0.05"})
    const inviteAddress = useInput("", {type: "string", placeholder: "invite address"})
    const {open} = useToast()
    const {
        button,
        loadingButton,
        initButton,
        // setButtonText,
        // setDisabled
    } = useButtonText("Mint GAGAPOS")

    button.onClick = async () => {
        loadingButton("Pending")
        const aiStake = AIStake()
        const aipos = AIPOS()

        let mintAmount = EtherToWei(inputAmount.value)
        let invite = inviteAddress.value
        try {
            if (invite === "") invite = ZREO_ADDRESS
            let tx = await aiStake.mint(aipos.address, invite, {value: mintAmount})
            await tx.wait()
            open("Mint GAGAPOS Success")

        } catch (error) {
            // message
            if (error.data) {
                open(WalletError(error), "error")
            } else {
                open("Sign Cancel", "error")
            }
            
        }
        
        // open("Approve Console", "error")
        initButton()
    }

    return {
        button,
        inviteAddress,
        inputAmount
    }
}

export function useMintAIETH() {
    // const {account, chainId} = useEthers()
    const inputAmount = useInput("", {type: "number", placeholder: "1"})
    const inviteAddress = useInput("", {type: "string", placeholder: "invite address"})
    const {open} = useToast()
    const {
        button,
        loadingButton,
        initButton,
        // setButtonText,
        // setDisabled
    } = useButtonText("Mint GAGAW")

    button.onClick = async () => {
        loadingButton("Pending")
        const aiStake = AIStake()
        const aipos = AIETH()

        let mintAmount = EtherToWei(inputAmount.value)
        let invite = inviteAddress.value
        try {
            console.log(
                aipos.address, invite
            )
            if (invite === "") invite = ZREO_ADDRESS
            let tx = await aiStake.mint(aipos.address, invite, {value: mintAmount})
            await tx.wait()
            open("Mint GAGAW Success")

        } catch (error) {
            console.log(error.data)
            // message
            if (error.data) {
                open(WalletError(error), "error")
            } else {
                open("Sign Cancel", "error")
            }
            
        }
        
        // open("Approve Console", "error")
        initButton()
    }

    return {
        button,
        inviteAddress,
        inputAmount
    }
}


export function useStake() {
    // const {account, chainId} = useEthers()
    const inputAmount = useInput("", {type: "number", placeholder: "1"})
    const stakeType = useInput("GAGAPOS")
    const {open} = useToast()
    const {
        button,
        loadingButton,
        initButton,
        // setButtonText,
        // setDisabled
    } = useButtonText("Stake")

    button.onClick = async () => {
        loadingButton("Pending")
        const aiStake = AIStake()
        const aipos = AIPOS()
        const aieth = AIETH()
        const aiai = AIAI()

        let mintAmount = EtherToWei(inputAmount.value)
        console.log(
            mintAmount, aiStake
        )
        // 这里可以封装成组建
        let {
            nonce,
            tx,
            error
        } = await Approves(
            [
                {
                    token: [aiai.address, 18, 'GAGA'],
                    amountWei: mintAmount,
                    sender: aiStake.address,
                    isMax: true,
                    needClear: false
                }
            ],
            {
                callback({error}) {
                    // console.log("callback ", result)
                    loadingButton("Approving GAGA")
                    if ( error ) {
                        open("Approve Console", "error")
                    } else {
                        open("Approve Success")
                    }
                    
               }
            }
        )

        try {
            if ( !error ) {
                let tx = await aiStake.stake(mintAmount, stakeType.value === 'GAGAPOS' ? aipos.address : aieth.address, {nonce})
                await tx.wait()
                open("Mint GAGAW Success")
            }
        } catch (error) {
            // message
            if (error.data) {
                // const meg = error.data.data.message.match(/'(.*?)'/g)
                open(WalletError(error), "error")
            } else {
                open("Sign Cancel", "error")
            }
            
        }
        
        // open("Approve Console", "error")
        initButton()
    }

    return {
        button,
        stakeType,
        inputAmount
    }
}


export function useClaim() {
    const {account} = useEthers()
    const {open} = useToast()
    const {
        button,
        loadingButton,
        initButton,
        // setButtonText,
        // setDisabled
    } = useButtonText("Claim")
    button.onClick = async () => {
        loadingButton("Pending")
        const aiStake = AIStake()
        try {
            let tx = await aiStake.claimFor(account)
            await tx.wait()
            open("Mint GAGAW Success")
        } catch (error) {
            // message
            if (error.data) {
                const meg = error.data.data.message.match(/'(.*?)'/g)
                open(meg ? meg[0].replace(/'/g,'') : error.data.data.message, "error")
            } else {
                open("Sign Cancel", "error")
            }
            
        }
        
        // open("Approve Console", "error")
        initButton()
    }
    return button
}