import {useContext, useEffect, createContext, useState} from 'react';

import {
    // HashRouter as Router,
    // BrowserRouter as Router,
    Route,
    Link,
    useHistory
} from "react-router-dom";

import {styled} from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';

// import Bridge from './Bridge';
import MintPos from './MintPos';
import MintAIETH from './MintAIETH';
import ClaimAI from './ClaimAI';

// MintPos
import Theme from './Theme';

import { 
    Button,
    TextL,
    TextS,
    TextM,
    TextLinear,
    WhiteSpace,
    Card,
    Input,
    Tips
} from '../components'

import Accordion from '../components/Accordion'

import Icon from '../components/Icon'

import Page from './Page'

import Logo from '../components/Logo'



export const Done = [
    {
        title: 'DAO',
        path: '/',
        exact: true,
        component: () => <Page><MintAIETH /></Page>
    },
    {
        title: 'Mint GAGAPOS',
        path: '/gagapos',
        exact: true,
        component: () => <Page><MintPos /></Page>
    },
    
    {
        title: 'Claim AI',
        path: '/claimai',
        exact: true,
        component: () => <Page><ClaimAI /></Page>
    }
]

export const Dev = [
    
    // {
    //     title: 'Swap',
    //     path: '/network',
    //     exact: true,
    //     // href: 'https://dapp.xpslab.pro/',
    //     component: null,
    //     disabled: true
    // }
]

// 隐形路由
export const INVISIBLE = [
    {
        title: 'Theme',
        path: '/theme',
        exact: true,
        component: Theme
    },
    // {
    //     title: 'Hash',
    //     path: '/hash',
    //     exact: true,
    //     component: CheckHash
    // }
    // {
    //     title: 'uAdmin',
    //     path: '/uadmin',
    //     exact: true,
    //     component: UAdmin
    // },
    // {
    //     title: 'tokens',
    //     path: '/tokens',
    //     exact: true,
    //     component: ChooseToken
    // }
    
]

export const RouterView = () => {
    const flat = [];
    [...Done, ...Dev,...INVISIBLE].forEach(v => {
        const {child, ...other} = v
        flat.push(<Route key={other.title} {...other}/>)
        if ( child ) {
            child.forEach(v => {
                flat.push(<Route key={v.title} {...v}/>)
            })
        }
    })
    return flat
};


export const RouterFlat = (() => {
    const flat = [];
    [...Done, ...Dev,...INVISIBLE].forEach(v => {
        const {child, ...other} = v
        flat.push(other)
        if ( child ) {
            child.forEach(v => {
                flat.push(v)
            })
        }
    })
    return flat
})();


const RouterListBlock = styled('div')`
    background: #f4c31e;
    color: #3b0901;
    position: relative;
    width: 240px;
    height: inherit;
`
const FilterBlock = styled('div')`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
    filter: ${p => p.opened?'blur(5px)':'none'};
    &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 100% 100%;
        z-index: -1;
    };
`

function RouteList() {
    const history = useHistory()
    const [paths, setPath] = useState(() => history.location.pathname)
    useEffect(() => {
        history.listen(({ pathname }) => {
            setPath(pathname)
        })
    }, [])

    return Done.map((val) => (
        <>
            <WhiteSpace />
            <Items path={paths} route={val} key={val.title} />
            <WhiteSpace />
            {/* <HrDotted /> */}
        </>
    ))
}

const contextInit = {
    opened: false,
    close: () => {},
    open: () => {}
}
export const Context = createContext(contextInit)

export function RouterDrawerProvider({children}) {
    const [opened,setOpen] = useState(false)
    const close =  () => setOpen(false)
    return (
        <Context.Provider
            value={{
                opened,
                close,
                open: () => setOpen(true)
            }}
        >
            <FilterBlock opened={opened}>
                {children}
            </FilterBlock>
            <Drawer
                anchor='right'
                onClose={close}
                open={opened}
            >
                <RouterListBlock>
                    <Div>
                        <Logo /> <TextL color='4' style={{marginLeft: '8px'}}> {process.env.TITLE} </TextL>
                    </Div>
                    <RouteList />
                    {/* <TextM_After>coming soon</TextM_After> */}
                </RouterListBlock>
            </Drawer>
        </Context.Provider>
    )
}

export function useRouterDrawer() {
    return useContext(Context)
}



function Items({ route, path, style }) {
    const [open, setOpen] = useState(false)
    const Item = ({ val, style }) => (
        <ListItem key={val.title} style={{
            textAlign: 'center',
            ...style
        }}>
            {/* <ListItemIcon>
                <Icon size='22' style={{color:'#fff',opacity: (path === val.path ? 1 : 0.68 )}} type={val.icon} />
            </ListItemIcon> */}
            <ListItemText
                style={{
                    marginLeft: '16px'
                }}
                primary={
                    <TextM
                        color={0}
                        style={{opacity: (path === val.path ? 1 : 0.6 )}}
                    >{val.title}</TextM>
                }
            />

            {val.open !== undefined ? <Icon size='14' type={open ? 'icon-changyong_shangshou' : 'icon-changyong_xiala'} /> : null}
        </ListItem>
    );
    if (route.child) {
        return (
            <Accordion
                square
                expanded={open}
                onChange={(_, n) => {
                    // console.log(n,'n')
                    setOpen(n)
                }}
                // style={style}
                title={<Item val={{ ...route, open }} />}
                list={route.child.map((v, i) => <Items style={{marginLeft:'8px'}}  path={path} route={v} key={i} />)}
            />
        )
    } else if (route.href) {
        return <a
            style={{
            width: '100%',
            pointerEvents:  route.disabled ? 'none' :  'all'
        }} rel="noreferrer" href={route.href} target="_blank"><Item val={route} /></a>
    } else {
        return (
            <LinkStyled
                to={route.path}
                active={path === route.path}
                key={route.title}
                style={{
                    width: '100%',
                    pointerEvents:  route.disabled ? 'none' :  'all'
                }}
            >
                <Item val={route} />
            </LinkStyled>
        )
    }
}

const LinkStyled = styled(Link)`
    display: block;
    color: ${props => props.active ? '#3b0901' : '#e3e3e3'};
    text-decoration: none;
`

const Div = styled('div')`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px 0px;
`

// const TextM_After = styled(TextS)`
//     position: relative;
//     display: block;
//     font-size: 12px;
//     color: #9f9fa1;
//     text-align: center;
//     &:after {
//         position: absolute;
//         content: '';
//         height: 1px;
//         width: 20%;
//         background: rgba(255,255,255,.4);
//         right: 5%;
//         top: 50%;
//     }
//     &:before {
//         position: absolute;
//         content: '';
//         height: 1px;
//         width: 20%;
//         background: rgba(255,255,255,.4);
//         top: 50%;
//         left: 5%;
//     }
// `