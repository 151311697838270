import {useEffect} from 'react'
import {
    ShortAddress,
    EtherToInt,
    EtherToWei,
    WeiToUnits,
    BN
} from 'ethers-easy'

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import {styled} from '@mui/material/styles';

import { 
    Button,
    TextL,
    TextXL,
    TextMax,
    TextS,
    TextM,
    TextLinear,
    WhiteSpace,
    Card,
    Input,
    Tips,
    Hr
} from '../../components'

import {
    IconImg,
    NoData
} from '../../components/Icon'

import GAGACard from '../Components/GAGACard'

import {
    useAIAI,
    useMintAIETH
} from '../../hook/aiCoin/useAI'

import useCopy from '../../hook/useCopy'

import {getUrlParams} from '../../utils'

const CardTitle = styled('div')`
    display: flex;
    background: #2b2b33;
    width: min-content;
    padding: 8px 26px 0px 8px;
    border-radius: 30px 30px 0 0;
    margin-bottom: -14px;
    align-items: center;
`

function MintPos() {
    const copy = useCopy()
    const ai = useAIAI()
    const {
        button,
        inviteAddress,
        inputAmount
    } = useMintAIETH()

    useEffect(() => {
        const {r} = getUrlParams()
        if (r) inviteAddress.onChange(r)
    }, [])
    const setMax = () => {
        inputAmount.onChange(ai.eth)
    }

    const aieth = WeiToUnits(EtherToWei(inputAmount.value).mul(200_000_000)).toString()

    const minInputDisabled = inputAmount.value * 1 < 0.05
    const balanceInsufficientDisabled = inputAmount.value * 1 > ai.eth * 1
    return (
        <Container>
            <GAGACard>
                <TextM>Mint Balance GAGAW</TextM>
                <TextMax
                    style={{
                        display: 'block',
                        lineHeight: '34px',
                        fontWeight: 600
                    }}
                >{EtherToInt(ai.aiethSupply)}</TextMax>
                <TextS>1 GAGAW Will Claim to 1 GAGA</TextS>
            </GAGACard>
            <WhiteSpace />
            {/* <CardTitle
                style={{
                    display: 'flex'
                }}
            >
                <IconImg 
                    src='/icon/brain.png'
                    size='24'
                    style={{
                        marginRight: '8px',
                        background: '#4e5d21',
                        padding: '6px',
                        borderRadius: '32px'
                    }}
                />
                <TextXL>DAO</TextXL>
            </CardTitle> */}
            <Card type='a' spacing={.8} >
                <Title >DAO</Title>
                <div style={{height: '10px', width:'100%'}}/>
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{padding:'0xp 6px',marginBottom:'6px'}}>
                    <TextM >Seed</TextM>
                    <TextM>
                        Balance: {EtherToInt(ai.eth)} ETH
                    </TextM>
                </Stack>
                <Input
                    inputStyle={{textAlign:'right'}}
                    before={<TextCoin>ETH</TextCoin>}
                    after={<Button size='small' onClick={setMax} style={{marginLeft: '4px', padding:'2px', minWidth: '41px'}}>Max</Button>}
                    input={inputAmount}
                />
                {
                    !ai.isIntvited && <>
                        <WhiteSpace size='.6' />
                        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{padding:'0xp 6px',marginBottom:'6px'}}>
                            <TextM >Invite Address</TextM>
                        </Stack>
                        <Input
                            input={inviteAddress}
                        />
                        <TextS>1 Invite Address Get 50% ETH Award</TextS>
                    </>
                }
                <WhiteSpace />
                {/* <Hr /> */}
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{padding:'0xp 6px',margin:'6px 0px'}}>
                    <TextM >Minted</TextM>
                    <TextM>
                        Balance: {EtherToInt(ai.aiethBalance)} GAGAW
                    </TextM>
                </Stack>
                <Input
                    inputStyle={{textAlign:'right'}}
                    before={<TextCoin>GAGAW</TextCoin>}
                    input={{
                        value: aieth,
                        readOnly: true,
                        placeholder: '2 100 000 000'
                    }}
                />
                <TextS style={{marginTop:'.6rem'}}>1 ETH = 200,000,000 GAGAW</TextS>
                <WhiteSpace />
                {
                    (inputAmount.value !== '' && minInputDisabled) && <TextS style={{color: '#c8550e',display:'block', marginBottom: '4px'}}>Minimum 0.05 ETH</TextS>
                }
                {
                    (inputAmount.value !== '' && balanceInsufficientDisabled) && <TextS style={{color: '#c8550e',display:'block', marginBottom: '4px'}}>ETH Balance Insufficient</TextS>
                }
                <Button size='large' type='b' style={{width:'100%'}} disabled={balanceInsufficientDisabled || minInputDisabled} {...button} />
            </Card>
            <WhiteSpace />
            <Card type='a' spacing={.8}>
                <TextL>Invite List</TextL>
                {/* <WhiteSpace /> */}
                {
                    ai.invites.length === 0 ? 
                    <NoData style={{margin: '20px auto', display:'block', opacity: 0}}/> :
                    ai.invites.map(v => (
                        <Card
                            key={v.address}
                            type='b'
                            style={{
                                marginTop: '6px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                            spacing={[.4, .8]}
                            onClick={() => {
                                copy(v.address)
                            }}
                        >
                            <TextM>{ShortAddress(v.address, 8)}</TextM>
                            <IconImg src='/icon/copy1.png' size='14'/>
                        </Card>
                    ))
                }
            </Card>
        </Container>
    )
}

export default MintPos

const TextCoin = styled(TextM)`
    padding: 2px 4px;
    /* background: #2b2b33; */
    /* border-radius: 6px; */
`

// function Wave({children, ...other}) {
//     return (
//         <WaveBlock {...other}>
//             <div className="wave"></div>
//             <div className="wave"></div>
//             <div className="wave"></div>
//             <div className='wave_context'>
//                 {children}
//             </div>
            
//         </WaveBlock>
//     )
// }

// const WaveBlock = styled('div')`
//     width:100%;
//     /* height: 80px; */
//     position: relative;
//     .wave_context {
//         position: relative;
//         z-index: 1;
//     }
//     .wave{
//         background-color: #2b2b33;
//         /* background-color: #3c4037a3; */
        
//         width: 150vw;
//         height: 200vw;
//         opacity: 0.8;
//         position: absolute;
//         top: -72vw;
//         left: 50%;
//         margin-left:-75vw;
//         margin-top: -109vw;
//         border-radius: 43%;
//         animation: rotate 15s infinite linear;
//         z-index: 0;
//         &:nth-child(1){
//             animation: rotate 18s infinite linear;
//         }
//         &:nth-child(2){
//             animation: rotate 21s infinite linear;
//             opacity: 0.1;
//             background-color: #777;
//         }
//     }
//     @keyframes rotate {
//         0%{
//             transform: rotate(0deg);
//         }
//         100%{
//             transform: rotate(360deg);
//         }
//     }
// `

const Title = styled(TextMax)`
    -webkit-text-stroke: 1px #3b0901;
    color: #f8cc4b;
    width: 100%;
    text-align: center;
    display: block;
    line-height: 28px;
    font-size: 28px;
    font-weight: 800;
`